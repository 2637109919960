import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';
export const SET_TAXIFLEET = '@account/set-taxifleet';

export const setSelectedTaxiFleet = (selectedTaxiFleet) => (dispatch) => {
  dispatch({
    type: SET_TAXIFLEET,
    payload: selectedTaxiFleet
  });
};

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const accountData = await authService.loginWithUsernameAndPassword(username, password);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        ...accountData
      }
    });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE });
    throw error;
  }
};

export const setAccountData = ({
  user, taxiFleets, permissions
}) => (dispatch) => dispatch({
  type: SILENT_LOGIN,
  payload: {
    user, taxiFleets, permissions
  }
});

export const logout = () => async (dispatch) => {
  authService.logout();

  dispatch({
    type: LOGOUT
  });
};
