/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_TAXIFLEET,
  SILENT_LOGIN,
  UPDATE_PROFILE
} from 'src/actions/accountActions';

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAXIFLEET: {
      return produce(state, (draft) => {
        draft.selectedTaxiFleet = action.payload;
      });
    }

    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.taxiFleets = null;
        draft.permissions = null;
      });
    }

    case LOGIN_SUCCESS: {
      const {
        user, taxiFleets, permissions
      } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.taxiFleets = taxiFleets;
        draft.permissions = permissions;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.taxiFleets = null;
        draft.permissions = null;
      });
    }

    case SILENT_LOGIN: {
      const {
        user, taxiFleets, permissions
      } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.taxiFleets = taxiFleets;
        draft.permissions = permissions;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
