import { getNotificationOptions } from 'src/api/notificationApi';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';
export const SET_NOTIFICATIONS = '@notifications/set-notifications';

export const getNotifications = () => {
  const request = getNotificationOptions();

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_NOTIFICATIONS,
      payload: response.data
    }));
  };
};

export const setNotifications = (notifications) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: notifications
  });
};
