import { PUSH_LOCATION } from 'src/actions/historyActions';

const initialState = {
  locations: []
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_LOCATION: {
      const newLocations = state.locations.slice();
      newLocations.push(action.payload);
      return { ...state, locations: newLocations };
    }
    default: {
      return state;
    }
  }
};

export default historyReducer;
