import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';

const TaxiFleetsMenu = ({ onChange, value }) => {
  const { taxiFleets } = useSelector((state) => state.account);
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <TextField
        fullWidth
        label={t('header.taxi_fleet')}
        name="taxiFleets"
        onChange={onChange}
        select
        SelectProps={{ native: true }}
        value={value}
        variant="outlined"
      >
        {taxiFleets.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </TextField>
    </Box>
  );
};
TaxiFleetsMenu.propTypes = {
  onChange: func.isRequired,
  value: string
};

export default TaxiFleetsMenu;
