import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { any, string } from 'prop-types';
import useHasPermission from 'src/hooks/useHasPermission';

const ProtectedRoute = ({ component: Component, permission, ...rest }) => {
  const hasPermission = useHasPermission(permission);

  return (
    <Route
      {...rest}
      render={(props) => (
        hasPermission ? <Component {...props} />
          : (
            <Redirect to="/login" />
          )
      )}
    />
  );
};
ProtectedRoute.propTypes = {
  component: any,
  permission: string
};


export default ProtectedRoute;
