import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { authenticateWithPasswordApi } from 'src/api/authApi';
import { getAuthToken, removeAuthToken, setAuthToken } from 'src/utils/auth-utils';
import { getLoggedUserDetailsApi, getUserPermissionsApi } from 'src/api/accountApi';
import store from 'src/store';
import { setNotifications } from 'src/actions/notificationsActions';
import ls from 'local-storage';
import Cookies from 'js-cookie';
import taxiFleetsService from './taxiFleetsService';

class AuthService {
  getAccessToken = () => getAuthToken();

  isAuthenticated = () => !!this.getAccessToken();

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status === 401) {
        this.setSession(null);

        if (onLogout) {
          onLogout();
        }
      }
      return Promise.reject(error);
    });
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    const isValidToken = this.isValidToken(accessToken);
    const session = isValidToken ? accessToken : null;
    this.setSession(session);
  }

  loginWithUsernameAndPassword = async (username, password) => {
    const { data } = await authenticateWithPasswordApi(username, password);
    this.setSession(data.jwt);
    return this.loginInWithToken();
  };

  loginInWithToken = async () => {
    const response = await getLoggedUserDetailsApi();
    const { taxiFleets, permissions } = await this.setupUserAccountData();
    const notifications = await this.getUserNotifications();
    store.dispatch(setNotifications(notifications));
    return {
      user: response.data,
      taxiFleets,
      permissions
    };
  };

  setupUserAccountData = async () => {
    const taxiFleets = await taxiFleetsService.getUserTaxiFleets();
    taxiFleetsService.setupDefaultTaxiFleet(taxiFleets);
    const permissions = await this.getUserPermissions();
    return { taxiFleets, permissions };
  };

  getUserPermissions = async () => {
    const response = await getUserPermissionsApi();
    return response.data;
  };

  getUserNotifications = async () => {
    return [];
  };

  removeCookies = () => {
    Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
  };

  logout = () => {
    this.setSession(null);
    this.removeCookies();
    ls.clear();
  };

  setSession = (accessToken) => {
    if (accessToken) {
      setAuthToken(accessToken);
    } else {
      removeAuthToken();
      taxiFleetsService.clearDefaultTaxiFleet();
    }
  };
}

export default new AuthService();
