import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import historyReducer from './historyReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  history: historyReducer,
  notifications: notificationsReducer
});

export default rootReducer;
