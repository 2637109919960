import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import * as locales from '@material-ui/core/locale';
import * as _ from 'lodash';
import 'moment/locale/ru';
import 'moment/locale/ro';
import history from 'src/history';
import getDefaultLanguage from './i18n/getDefaultLanguage';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

mapboxgl.accessToken = 'pk.eyJ1IjoidmxhZG1hdHZlaSIsImEiOiJjbGJuYzBpcHcwOHJ5M25sOGpqbXdpanI3In0.fw6EF_j67v3A5LZH46S59Q';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));


const firebaseConfig = {
  apiKey: "AIzaSyDjVbQsB0-1MXMXq4U36hsYsGftbApQSXU",
  authDomain: "teleporttaxi-a8083.firebaseapp.com",
  databaseURL: "https://teleporttaxi-a8083-default-rtdb.firebaseio.com",
  projectId: "teleporttaxi-a8083",
  storageBucket: "teleporttaxi-a8083.appspot.com",
  messagingSenderId: "1004038282376",
  appId: "1:1004038282376:web:73f62b176bd0581831d33d",
  measurementId: "G-JV9F62VFX0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);

function App({ coords }) {
  useStyles();

  const { settings } = useSettings();
  const language = settings.language || getDefaultLanguage();

  const locale = locales[_.replace(language, '_', '')];
  const momentLocale = _.split(language, '_', 1)[0];
  moment.locale(momentLocale);

  useEffect(() => {
    localStorage.setItem('latitude', coords?.latitude);
    localStorage.setItem('longitude', coords?.longitude);
  }, [coords]);

  return (
    <ThemeProvider theme={createTheme(settings, locale)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={momentLocale}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <CookiesNotification />
                <SettingsNotification />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
