import axios from 'axios';
import * as qs from 'qs';
import store from 'src/store';
import taxiFleetsService from 'src/services/taxiFleetsService';
import { logout } from 'src/actions/accountActions';
import { getAuthToken } from 'src/utils/auth-utils';

const API_BASE_URL = 'https://api.teleport.md';
const { CancelToken } = axios;

axios.interceptors.response.use(null, (error) => {
  const status = error.status ? error.status : error.response && error.response.status;
  if (status === 401 || status === 403) {
    store.dispatch(logout());
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  const taxiFleetCode = taxiFleetsService.getSavedTaxiFleet();
  if (taxiFleetCode) {
    const params = { ...config.params, taxiFleetCode };
    return { ...config, params };
  }
  return config;
});

export const buildUrl = (url) => `${API_BASE_URL}${url}`;

const getConfig = (canceling) => {
  const token = getAuthToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  return {
    headers,
    cancelToken: new CancelToken(((c) => {
      if (canceling) {
        canceling(c);
      }
    })),
    paramsSerializer: (data) => qs.stringify(data, { arrayFormat: 'repeat' })
  };
};

export const get = (url, data, cancelling) => {
  const config = getConfig(cancelling);
  config.params = data;
  return axios.get(buildUrl(url), config);
};

export const post = (url, data, cancelling) => axios.post(buildUrl(url), data, getConfig(cancelling));

export const put = (url, data, cancelling) => axios.put(buildUrl(url), data, getConfig(cancelling));

export const patch = (url, data, cancelling) => axios.patch(buildUrl(url), data, getConfig(cancelling));

export const del = (url, canceling) => axios.delete(buildUrl(url), getConfig(canceling));
