import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import 'src/mixins/chartjs';
import 'mapbox-gl/dist/mapbox-gl.css';
import { enableES5 } from 'immer';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import store from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import './i18n/i18n';
import App from 'src/App';

enableES5();

const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
