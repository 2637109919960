import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { func, bool } from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  AttachMoney,
  BarChart,
  Phone,
  ContactsOutlined,
  HistoryOutlined,
  LocalTaxiOutlined,
  PeopleOutlined,
  ListAlt,
  Map
} from '@material-ui/icons';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-i18next';
import Permissions from 'src/permissions';
import NavItem from './NavItem';

const navConfig = {
  reports: {
    subheader: 'navbar.reports.label',
    items: [
      {
        title: 'navbar.reports.dashboard',
        icon: BarChart,
        href: '/app/reports/dashboard'
      },
      {
        title: 'navbar.reports.driversMap',
        icon: Map,
        permission: Permissions.MANAGEMENT_DRIVERS_READ,
        href: '/app/reports/driver-map'
      },
      {
        title: 'navbar.reports.taxiDispatcher',
        icon: Phone,
        permission: Permissions.REPORTS_DISPATCHER_READ,
        href: '/app/reports/taxi-dispatcher'
      },
      {
        title: 'navbar.reports.dispatch_history',
        icon: ListAlt,
        permission: Permissions.REPORTS_RIDES_HISTORY_READ,
        href: '/app/reports/dispatch-history'
      },
      {
        title: 'navbar.reports.rides_history',
        icon: HistoryOutlined,
        permission: Permissions.REPORTS_RIDES_HISTORY_READ,
        href: '/app/reports/rides-history'
      },
      {
        title: 'navbar.reports.billing',
        icon: AttachMoney,
        permission: Permissions.REPORTS_BILLING_READ,
        href: '/app/reports/billing'
      }
    ]
  },
  management: {
    subheader: 'navbar.management.label',
    drivers: {
      title: 'navbar.management.drivers.label',
      icon: PeopleOutlined,
      items: [
        {
          title: 'navbar.management.drivers.list_drivers',
          permission: Permissions.MANAGEMENT_DRIVERS_READ,
          href: '/app/management/drivers'
        },
        {
          title: 'navbar.management.drivers.list_working_conditions',
          permission: Permissions.MANAGEMENT_WORKING_CONDITIONS_READ,
          href: '/app/management/working-conditions'
        }
      ]
    },
    vehicles: {
      title: 'navbar.management.vehicles.label',
      icon: LocalTaxiOutlined,
      permission: Permissions.MANAGEMENT_VEHICLES_READ,
      href: '/app/management/vehicles'
    },
    users: {
      title: 'navbar.management.users.label',
      icon: ContactsOutlined,
      href: '/app/management/users',
      items: [
        {
          title: 'navbar.management.users.list_users',
          permission: Permissions.MANAGEMENT_USERS_WRITE,
          href: '/app/management/users'
        },
        {
          title: 'navbar.management.users.list_user_groups',
          permission: Permissions.MANAGEMENT_USER_GROUPS_WRITE,
          href: '/app/management/user-groups'
        }
      ]
    }
  }
};

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    height: 70
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user, permissions } = useSelector((state) => state.account);
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const filterPermissionItems = (items) => items.filter((i) => {
    if (i.permission) {
      return !!permissions.includes(i.permission);
    }
    return true;
  });


  const navItems = [];
  const reportItems = navConfig.reports.items;
  const reports = filterPermissionItems(reportItems);
  if (reports.length > 0) {
    navItems.push({
      subheader: t(navConfig.reports.subheader),
      items: reports.map((i) => ({ ...i, title: t(i.title) }))
    });
  }

  const management = {
    subheader: t(navConfig.management.subheader),
    items: []
  };

  const { drivers, vehicles, users } = navConfig.management;
  const driversItems = filterPermissionItems(drivers.items, permissions);
  const vehiclesItems = permissions.includes(vehicles.permission, permissions) ? vehicles : null;
  const usersItems = filterPermissionItems(users.items, permissions);

  if (driversItems.length > 0) {
    management.items.push({
      title: t(drivers.title),
      icon: drivers.icon,
      items: driversItems.map((i) => ({ ...i, title: t(i.title) }))
    });
  }

  if (vehiclesItems) {
    management.items.push({ ...vehiclesItems, title: t(vehiclesItems.title) });
  }

  if (usersItems.length > 0) {
    management.items.push({
      title: t(users.title),
      icon: users.icon,
      items: usersItems.map((i) => ({ ...i, title: t(i.title) }))
    });
  }

  if (management.items.length > 0) {
    navItems.push(management);
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.givenName} ${user.surname}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navItems.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: func,
  openMobile: bool
};

export default NavBar;
