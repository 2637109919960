export default {
  ro_RO: {
    flag: 'romanian',
    label: 'Română'
  },
  ru_RU: {
    flag: 'russian',
    label: 'Русский'
  },
  en_US: {
    flag: 'english',
    label: 'English'
  },
};
