import React from 'react';
import PropTypes from 'prop-types';

function Logo({ className, ...props }) {
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      className={className}
      {...props}
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string
};

export default Logo;
