import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import taxiFleetsService from 'src/services/taxiFleetsService';
import LanguageMenu from './LanguageMenu';
import TaxiFleetsMenu from './TaxiFleetsMenu';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const isMountedRef = useIsMountedRef();
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    language: settings.language
  });
  const [taxiFleet, setTaxiFleet] = useState(taxiFleetsService.getSavedTaxiFleet());
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = async () => {
    handleClose();
    const isTaxiFleetChanged = taxiFleet !== taxiFleetsService.getSavedTaxiFleet();

    if (!isMountedRef.current) return;
    saveSettings(values);

    if (!isMountedRef.current) return;
    await i18n.changeLanguage(values.language);

    if (isTaxiFleetChanged && isMountedRef.current) {
      await taxiFleetsService.onChangeTaxiFleet(taxiFleet);
    }
  };

  return (
    <>
      <Tooltip title={t('header.settings')}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {t('header.settings')}
        </Typography>
        <Box mt={2}>
          <TaxiFleetsMenu
            value={taxiFleet}
            onChange={(event) => setTaxiFleet(event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <LanguageMenu
            value={values.language}
            onChange={(event) => handleChange('language', event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label={t('header.theme')}
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {t(`header.theme_options.${theme}`)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('common.save')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
