import { find } from 'lodash';
import { getTaxiFleetsApi } from 'src/api/taxiFleetApi';
import store from 'src/store';
import history from 'src/history';
import { setSelectedTaxiFleet } from '../actions/accountActions';

class TaxiFleetsService {
  getUserTaxiFleets = () => new Promise((resolve, reject) => {
    getTaxiFleetsApi().then((r) => resolve(r.data)).catch((error) => reject(error));
  });

  setupDefaultTaxiFleet = (taxiFleets) => {
    if (taxiFleets && taxiFleets.length > 0) {
      const taxiFleet = this.getDefaultOrFirst(taxiFleets);
      this.saveTaxiFleet(taxiFleet.code);
      return taxiFleet;
    }
    return null;
  };

  getDefaultOrFirst = (taxiFleets) => {
    const savedCode = this.getSavedTaxiFleet();
    if (savedCode) {
      const foundTaxiFleet = find(taxiFleets, { code: savedCode });
      if (foundTaxiFleet) {
        return foundTaxiFleet;
      }
    }
    return taxiFleets[0];
  };

  saveTaxiFleet = (taxiFleet) => {
    localStorage.setItem('taxiFleet', taxiFleet);
  };

  onChangeTaxiFleet = async (taxiFleet) => {
    this.saveTaxiFleet(taxiFleet);
    await store.dispatch(setSelectedTaxiFleet(taxiFleet));
    history.push('/app/reports/dashboard');
  };

  getSavedTaxiFleet = () => localStorage.getItem('taxiFleet');

  clearDefaultTaxiFleet = () => {
    localStorage.removeItem('taxiFleet');
  };
}

export default new TaxiFleetsService();
