import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import settingsLanguageDetector from './settingsLanguageDetector';

import languages from './languages';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(settingsLanguageDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: Object.keys(languages),
    whitelist: Object.keys(languages),
    supportedLngs: Object.keys(languages),
    debug: true,

    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['settingsLanguageDetector']
    }
  });


export default i18n;
