import { get, patch } from './api';

const BASE_URL = '/api/management/iam/users';

export const getLoggedUserDetailsApi = () => get(`${BASE_URL}/me`);

export const getUserPermissionsApi = () => get(`${BASE_URL}/me/permissions`);

export const changePasswordApi = (currentPassword, newPassword) => patch(`${BASE_URL}/me/password`, { currentPassword, newPassword });

export const changeProfileDetailsApi = (request) => patch(`${BASE_URL}/me/profile`, request);
