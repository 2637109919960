import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Bell as BellIcon, MessageCircle as MessageIcon } from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';
import { NotificationType } from 'src/constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Badge from '@material-ui/core/Badge';
import { DD_MM_YYYY_HH_MM_FORMAT } from 'src/utils/moment-utils';
import { markNotificationAsRead } from 'src/api/notificationApi';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 20,
    width: 20,
    borderRadius: 10,
    marginTop: 5,
    marginRight: 5,
    backgroundColor: theme.palette.error.main
  },
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  readIcon: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    height: 15,
    width: 15
  },
  notificationDate: {
    color: theme.palette.secondary.main
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notifications.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClickNotification = (resourceLink, notification) => {
    history.push(resourceLink);
    if (!notification.read) {
      markNotificationAsRead(notification.code).then(() => dispatch(getNotifications()));
    }
  };

  return (
    <>
      <Tooltip title={t('header.notifications')}>
        <Badge
          badgeContent={filter(notifications, ['read', false]).length}
          classes={{ badge: classes.badge }}
          overlap="circle"
        >
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            {t('header.notifications')}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              {t('header.no_notifications')}
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification) => {
                const notificationType = NotificationType[notification.type] || {};
                const Icon = notificationType ? notificationType.icon : MessageIcon;
                const message = notificationType ? t(notificationType.message, { code: notification.resourceCode }) : '';
                const color = notification.read ? 'textSecondary' : 'primary';
                const resourceLink = notification.resourceCode ? notificationType.getResourceLink(notification.resourceCode) : '#';

                return (
                  <ListItem
                    className={classes.listItem}
                    divider
                    key={notification.code}
                    button
                    onClick={() => handleOnClickNotification(resourceLink, notification)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={message}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={moment(notification.createdDate).format(DD_MM_YYYY_HH_MM_FORMAT)}
                      secondaryTypographyProps={{ color }}
                    />
                    {!notification.read && (
                      <Avatar variant="circle" className={classes.readIcon}>
                        <SvgIcon fontSize="small" />
                      </Avatar>
                    )}
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="/app/management/notifications"
              >
                {t('common.show_more')}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
