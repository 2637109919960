import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { logout, setAccountData } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { useHistory } from 'react-router-dom';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const { selectedTaxiFleet } = useSelector((state) => state.account);

  useEffect(() => {
    const initAuth = async () => {
      setLoading(true);
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        try {
          const { user, taxiFleets, permissions } = await authService.loginInWithToken();
          if (taxiFleets.length === 0) {
            dispatch(logout());
          } else {
            dispatch(setAccountData({ user, taxiFleets, permissions }));
          }
        } catch (e) {
          history.push('/login');
        }
      }
      setLoading(false);
    };
    initAuth();
  }, [dispatch, history, selectedTaxiFleet]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
};

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
