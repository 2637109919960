import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import languages from 'src/i18n/languages';
import { func, string } from 'prop-types';

const LanguageMenu = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <TextField
        fullWidth
        label={t('header.language')}
        name="language"
        onChange={onChange}
        select
        SelectProps={{ native: true }}
        value={value}
        variant="outlined"
      >
        {Object.keys(languages).map((key) => (
          <option key={key} value={key}>
            {languages[key].label}
          </option>
        ))}
      </TextField>
    </Box>
  );
};

LanguageMenu.propTypes = {
  value: string,
  onChange: func.isRequired
};
export default LanguageMenu;
