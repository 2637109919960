import { merge, omit } from 'lodash';

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
};

export const restoreSettingsValue = (fieldName) => {
  const settings = restoreSettings();
  return settings ? settings[fieldName] : null;
};

export const storeSettings = (settings) => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

export const storeSettingsValue = (fieldName, value) => {
  const settings = restoreSettings();
  storeSettings(merge(settings, { [fieldName]: value }));
};

export const removeSettingsValue = (fieldName) => {
  const settings = restoreSettings();
  storeSettings(omit(settings, [fieldName]));
};
