import { restoreSettingsValue, storeSettingsValue } from 'src/utils/settings';
import getDefaultLanguage from './getDefaultLanguage';

export default {
  name: 'settingsLanguageDetector',

  lookup() {
    const lng = restoreSettingsValue('language') || getDefaultLanguage();
    storeSettingsValue('language', lng);
    return lng;
  },

  cacheUserLanguage(lng) {
    storeSettingsValue('language', lng);
  },
};
