import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';
import ProtectedRoute from 'src/components/ProtectedRoute';
import Permissions from 'src/permissions';

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/app"
        />
        <Route
          exact
          path="/404"
          component={lazy(() => import('src/views/pages/Error404View'))}
        />
        <GuestRoute
          exact
          path="/policy"
          component={lazy(() => import('src/views/auth/Policy'))}
        />
        <GuestRoute
          exact
          path="/policy-passenger"
          component={lazy(() => import('src/views/auth/PolicyPassenger'))}
        />
        <GuestRoute
          exact
          path="/login"
          component={lazy(() => import('src/views/auth/LoginView'))}
        />
        <AuthRoute
          path="/app"
          render={(props) => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect
                    exact
                    from="/app"
                    to="/app/reports/dashboard"
                  />
                  <Route
                    exact
                    path="/app/account"
                    component={lazy(() => import('src/views/pages/AccountView'))}
                  />
                  <Route
                    exact
                    path="/app/reports/dashboard"
                    component={lazy(() => import('src/views/reports/DashboardView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/dispatch-history"
                    permission={Permissions.REPORTS_RIDES_HISTORY_READ}
                    component={lazy(() => import('src/views/reports/RideDispatchHistory/ListDispatchHistoryView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/rides-history"
                    permission={Permissions.REPORTS_RIDES_HISTORY_READ}
                    component={lazy(() => import('src/views/reports/RidesHistory/ListRidesView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/rides/:rideCode"
                    permission={Permissions.REPORTS_RIDES_HISTORY_READ}
                    component={lazy(() => import('src/views/reports/RidesHistory/RideDetailsView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/own-ride/:rideCode"
                    permission={Permissions.REPORTS_DISPATCHER_READ}
                    component={lazy(() => import('src/views/reports/OwnRideDetails'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/taxi-dispatcher"
                    permission={Permissions.REPORTS_DISPATCHER_READ}
                    component={lazy(() => import('src/views/reports/TaxiDispatcher'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/taxi-dispatcher/blacklist"
                    permission={Permissions.REPORTS_DISPATCHER_READ}
                    component={lazy(() => import('src/views/reports/TaxiDispatcher/Blacklist'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/billing"
                    permission={Permissions.REPORTS_BILLING_READ}
                    component={lazy(() => import('src/views/reports/BillingView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/reports/driver-map"
                    permission={Permissions.MANAGEMENT_DRIVERS_READ}
                    component={lazy(() => import('src/views/reports/DriverMapView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/drivers"
                    permission={Permissions.MANAGEMENT_DRIVERS_READ}
                    component={lazy(() => import('src/views/management/Driver/ListDriversView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/drivers/add"
                    permission={Permissions.MANAGEMENT_DRIVERS_READ}
                    component={lazy(() => import('src/views/management/Driver/DriverFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/drivers/:driverCode/edit"
                    permission={Permissions.MANAGEMENT_DRIVERS_READ}
                    component={lazy(() => import('src/views/management/Driver/DriverFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/drivers/:driverCode/balance"
                    permission={Permissions.MANAGEMENT_DRIVERS_WRITE}
                    component={lazy(() => import('src/views/management/Driver/DriverBalanceView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/working-conditions"
                    permission={Permissions.MANAGEMENT_WORKING_CONDITIONS_READ}
                    component={lazy(() => import('src/views/management/WorkingConditions/ListWorkingConditionsView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/working-conditions/add"
                    permission={Permissions.MANAGEMENT_WORKING_CONDITIONS_READ}
                    component={lazy(() => import('src/views/management/WorkingConditions/WorkingConditionsFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/working-conditions/:workingConditionsCode/edit"
                    permission={Permissions.MANAGEMENT_WORKING_CONDITIONS_READ}
                    component={lazy(() => import('src/views/management/WorkingConditions/WorkingConditionsFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/vehicles"
                    permission={Permissions.MANAGEMENT_VEHICLES_READ}
                    component={lazy(() => import('src/views/management/Vehicle/ListVehiclesView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/vehicles/add"
                    permission={Permissions.MANAGEMENT_VEHICLES_READ}
                    component={lazy(() => import('src/views/management/Vehicle/VehiclesFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/vehicles/:vehicleCode/edit"
                    permission={Permissions.MANAGEMENT_VEHICLES_READ}
                    component={lazy(() => import('src/views/management/Vehicle/VehiclesFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/users"
                    permission={Permissions.MANAGEMENT_USERS_WRITE}
                    component={lazy(() => import('src/views/management/User/ListUsersView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/users/add"
                    permission={Permissions.MANAGEMENT_USERS_WRITE}
                    component={lazy(() => import('src/views/management/User/UserFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/users/:userCode/edit"
                    permission={Permissions.MANAGEMENT_USERS_WRITE}
                    component={lazy(() => import('src/views/management/User/UserFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/user-groups"
                    permission={Permissions.MANAGEMENT_USER_GROUPS_WRITE}
                    component={lazy(() => import('src/views/management/UserGroup/ListUserGroupsView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/user-groups/add"
                    permission={Permissions.MANAGEMENT_USER_GROUPS_WRITE}
                    component={lazy(() => import('src/views/management/UserGroup/UserGroupFormView'))}
                  />
                  <ProtectedRoute
                    exact
                    path="/app/management/user-groups/:userGroupCode/edit"
                    permission={Permissions.MANAGEMENT_USER_GROUPS_WRITE}
                    component={lazy(() => import('src/views/management/UserGroup/UserGroupFormView'))}
                  />
                  <Redirect to="/404" />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
